import "./index.scss";

/**
 * Import modules
 */

import Inputmask from "inputmask/dist/inputmask.min.js";
import { Fancybox } from "@fancyapps/ui";
import Swiper from "swiper/bundle";
import lozad from "lozad";
import Toastify from "toastify-js";

/**
 * Helpers
 */
const cyrValue = (str) => {
  return /[^а-яА-ЯёЁ -]/gi.test(str);
};
const fancyboxShow = (src, type) => {
  Fancybox.show(
    [
      {
        src: src,
        type: type,
      },
    ],
    {
      autoFocus: false,
    }
  );
};
const addModalEvent = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("modal-rendered") == false) {
      el.classList.add("modal-rendered");

      el.addEventListener("click", (evt) => {
        evt.preventDefault();

        const button = evt.currentTarget,
          target = button.hash || button.dataset.href,
          popup = document.querySelector(target),
          title = button.dataset.modalTitle,
          content = button.dataset.modalContent,
          footer = button.dataset.modalFooter,
          submit = button.dataset.modalSubmit,
          yaGoal = button.dataset.modalYagoal,
          email = button.dataset.modalEmail;

        // if (title) popup.querySelector(".modal__title span").innerHTML = title;
        if (submit) popup.querySelector("input[type='submit']").value = submit;
        if (yaGoal) popup.querySelector("input[name='ya_goal']").value = yaGoal;
        if (email)
          popup.querySelector("input[name='email_title']").value = email;
        if (target) fancyboxShow(target, "inline");
      });
    }
  });
};
const inputValidate = (selector) => {
  document.querySelectorAll(selector).forEach((el) => {
    if (el.classList.contains("validate-rendered") == false) {
      el.classList.add("validate-rendered");

      if (selector === "input[name='agreement']") {
        el.addEventListener("change", () => {
          const status = el.checked;

          if (status) {
            el.closest("form")
              .querySelector("input[type='submit']")
              .removeAttribute("disabled");
            el.closest("form")
              .querySelector(".form__submit")
              .classList.remove("disabled");
          } else {
            el.closest("form")
              .querySelector("input[type='submit']")
              .setAttribute("disabled", "");
            el.closest("form")
              .querySelector(".form__submit")
              .classList.add("disabled");
          }
        });
      }
      if (selector === "input[name='name']") {
        let counter = 0;

        el.addEventListener("keyup", () => {
          if (cyrValue(el.value)) {
            counter++;

            if (counter > 5) {
              toast("Внимание!", "Имя должно быть на русском языке", "warning");
              counter = 0;
            }
          }

          el.value = el.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
        });
        el.addEventListener("blur", () => {
          if (el.value.length > 0) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");
          }
        });
      }
      if (selector === "input[name='phone']") {
        let im = new Inputmask({
          mask: "+7 (999) 999-99-99",
          definitions: {
            e: {
              validator: "[0-7,9]",
            },
          },
        });
        im.mask(el);

        el.addEventListener("blur", () => {
          if (el.value[17] == "_" || el.value[17] == undefined) {
            toast("Внимание!", "Некорректный номер", "warning");
            el.parentNode.classList.add("error");
            el.parentNode.classList.remove("valid");
          } else {
            el.parentNode.classList.add("valid");
            el.parentNode.classList.remove("error");
          }
        });
      }
      if (selector === "input[name='file_attach[]']") {
        el.addEventListener("change", () => {
          let totalSize = 0;
          Object.keys(e.currentTarget.files).map((objectKey, index) => {
            totalSize = totalSize + el.files[index].size;
          });
          if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
            el.value = "";
            toast(
              "Внимание!",
              "Максимальный объём вложений - 10 мб.",
              "warning"
            );
          } else if (el.files.length > 10) {
            el.value = "";
            toast(
              "Внимание!",
              "Максимальное количество вложений - 10 шт.",
              "warning"
            );
          } else {
            el.closest("form").querySelector("#file-name").innerText =
              el.files.length +
              " шт. (" +
              Math.round((totalSize / 1048576) * 100) / 100 +
              " мб.)";
            el.closest("form")
              .querySelector("p.filename")
              .classList.add("is-loaded");
          }
        });
        el.closest("form")
          .querySelector("#file-clear")
          .addEventListener("click", (evt) => {
            el.value = "";
            evt.currentTarget
              .closest("form")
              .querySelector("p.filename")
              .classList.remove("is-loaded");
            document.querySelector("#file-name").innerText = "";
          });
      }
      if (
        selector === "input[type='email']" ||
        selector === "input[type='text']" ||
        selector === "textarea"
      ) {
        el.addEventListener("blur", () => {
          if (el.value.length > 0) {
            el.parentNode.classList.add("valid");
          } else {
            el.parentNode.classList.remove("valid");
          }
        });
      }
      if (selector === "input[required]") {
        el.addEventListener("blur", () => {
          if (el.value.length == 0) {
            toast("Внимание!", "Поле обязательно для заполнения", "warning");
          }
        });
      }
    }
  });
};
const toast = (title = "", text = "", type = "info") => {
  let message, messageTitle, messageText;
  message = document.createElement("div");
  messageTitle = document.createElement("p");
  messageTitle.classList.add("title");
  messageTitle.innerHTML = title;
  messageText = document.createElement("p");
  messageText.classList.add("text");
  messageText.innerHTML = text;
  message.append(messageTitle, messageText);

  Toastify({
    className: type,
    close: true,
    destination: false,
    duration: 3000,
    escapeMarkup: false,
    gravity: "bottom",
    position: "right",
    stopOnFocus: true,
    text: message.innerHTML,
    onClick: () => {},
  }).showToast();
};

/**
 * Fields validation
 */
if (document.querySelector("input[name='agreement']")) {
  document.querySelectorAll("input[name='agreement']").forEach((el) => {
    el.addEventListener("change", (evt) => {
      const status = evt.currentTarget.checked;

      if (status) {
        evt.currentTarget
          .closest("form")
          .querySelector("input[type='submit']")
          .removeAttribute("disabled");
      } else {
        evt.target
          .closest("form")
          .querySelector("input[type='submit']")
          .setAttribute("disabled", "");
      }
    });
  });
}
if (document.querySelectorAll("input[name='name']")) {
  document.querySelectorAll("input[name='name']").forEach((el) => {
    let counter = 0;

    el.addEventListener("keyup", (evt) => {
      if (cyrValue(evt.target.value)) {
        counter++;

        if (counter > 5) {
          toast("Внимание!", "Имя должно быть на русском языке", "warning");
          counter = 0;
        }
      }

      evt.target.value = evt.target.value.replace(/[^а-яА-ЯёЁ -]/gi, "");
    });
  });
}
if (document.querySelector("input[name='phone']")) {
  document.querySelectorAll("input[name='phone']").forEach((el) => {
    let im = new Inputmask({
      mask: "+7 (e99) 999-99-99",
      definitions: {
        e: {
          validator: "[0-7,9]",
        },
      },
    });
    im.mask(el);

    el.addEventListener("blur", (evt) => {
      if (
        evt.currentTarget.value[17] == "_" ||
        evt.currentTarget.value[17] == undefined
      ) {
        toast("Внимание!", "Некорректный номер", "WARNING");
        evt.target.parentNode.classList.add("error");
        evt.target.parentNode.classList.remove("valid");
      } else {
        evt.target.parentNode.classList.add("valid");
        evt.target.parentNode.classList.remove("error");
      }
    });
  });
}
if (document.querySelector("input[required]")) {
  document.querySelectorAll("input[required]").forEach((el) => {
    el.addEventListener("blur", (evt) => {
      if (evt.target.value.length == 0) {
        toast("Внимание!", "Поле обязательно для заполнения", "WARNING");
      }
    });
  });
}
if (
  document.querySelector("input[type='text'], input[type='email'], textarea")
) {
  document
    .querySelectorAll("input[type='text'], input[type='email'], textarea")
    .forEach((el) => {
      el.addEventListener("blur", (evt) => {
        if (evt.target.value.length > 0) {
          evt.target.parentNode.classList.add("valid");
        } else {
          evt.target.parentNode.classList.remove("valid");
        }
      });
    });
}

/**
 * Form
 */
if (document.querySelector("form.fetch")) {
  document.querySelectorAll("form.fetch").forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();
      //let yaGoal = form.querySelector("input[name='ya_goal']").value;

      fancyboxShow("#loading", "inline");
      let data = new FormData(form);
      mna100("email", data, (r) => {
        if (r.status === 1) {
          Fancybox.close();
          if (form.querySelector("input[type='submit']"))
            form
              .querySelector("input[type='submit']")
              .removeAttribute("disabled", "");
          if (form.querySelector("button[type='submit']"))
            form
              .querySelector("button[type='submit']")
              .removeAttribute("disabled", "");

          form.querySelectorAll("input").forEach((inp) => {
            if (
              inp.type !== "submit" &&
              inp.type !== "checkbox" &&
              inp.type !== "radio" &&
              inp.type !== "hidden"
            ) {
              inp.value = "";
              inp.parentNode.classList.remove("valid");
            }
          });
          if (form.querySelector("input[type='checkbox']")) {
            form.querySelectorAll("input[type='checkbox']").forEach((el) => {
              if (el.name != "agreement") el.checked = false;
            });
          }

          setTimeout(() => {
            fancyboxShow("#thanks", "inline");
          }, 150);
          setTimeout(() => {
            Fancybox.close();
          }, 5000);

          if (typeof ym == "function") {
            ym("XXXXXXXX", "reachGoal", yaGoal);
            console.log("Цель достигнута: " + yaGoal);
          }
          if (typeof gtag == "function") {
            //gtag("event", "form_lead", {"event_category": "lead", "event_action": "zayavka"});
          }
        } else {
          toast("Внимание!", "Ошибка ! " + r.message, "WARNING");

          Fancybox.close();
        }
      });

      // let phone = form.querySelector("input[name='phone']").value;

      // if (phone.length[17] === "_" || phone.length === 0) {
      //   document.querySelector("input[name='phone']").classList.add("is-error");
      // } else {
      //   if (form.querySelector("input[type='submit']"))
      //     form
      //       .querySelector("input[type='submit']")
      //       .setAttribute("disabled", "");
      //   if (form.querySelector("input[type='submit']"))
      //     form
      //       .querySelector("input[type='submit']")
      //       .setAttribute("disabled", "");

      //   let data = new FormData(form);
      //   mna100("email", data, (r) => {
      //     if (r.status === 1) {

      //       Fancybox.close();
      //       if (form.querySelector("input[type='submit']"))
      //         form
      //           .querySelector("input[type='submit']")
      //           .removeAttribute("disabled", "");
      //       if (form.querySelector("button[type='submit']"))
      //         form
      //           .querySelector("button[type='submit']")
      //           .removeAttribute("disabled", "");

      //       form.querySelectorAll("input").forEach((inp) => {
      //         if (
      //           inp.type !== "submit" &&
      //           inp.type !== "checkbox" &&
      //           inp.type !== "radio" &&
      //           inp.type !== "hidden"
      //         ) {
      //           inp.value = "";
      //           inp.parentNode.classList.remove("valid");
      //         }
      //       });
      //       if (form.querySelector("input[type='checkbox']")) {
      //         form.querySelectorAll("input[type='checkbox']").forEach((el) => {
      //           if (el.name != "agreement") el.checked = false;
      //         });
      //       }

      //       setTimeout(() => {
      //         fancyboxShow("#thanks", "inline");
      //       }, 150);
      //       setTimeout(() => {
      //         Fancybox.close();
      //       }, 5000);

      //       if (typeof ym == "function") {
      //         ym("XXXXXXXX", "reachGoal", yaGoal);
      //         console.log("Цель достигнута: " + yaGoal);
      //       }
      //       if (typeof gtag == "function") {
      //         //gtag("event", "form_lead", {"event_category": "lead", "event_action": "zayavka"});
      //       }
      //     } else {
      //       toast("Внимание!", "Ошибка ! " + r.message, "WARNING");

      //       Fancybox.close();
      //     }
      //   });
      // }
    });
  });
}

/**
 * Modal
 */
if (document.querySelector(".modal-link")) addModalEvent(".modal-link");

/**
 * Lazy load
 */
const observer = lozad();
observer.observe();

/**
 * Hero swiper
 */
if (document.querySelector(".swiper.hero__swiper")) {
  new Swiper(".swiper.hero__swiper", {
    breakpoints: {
      320: {
        slidesPerView: 1.45,
      },
      767: {
        slidesPerView: 2,
      },
      1250: {
        slidesPerView: 3,
      },
    },
    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 1,
    navigation: {
      nextEl: ".hero__swiper__btn-next",
      prevEl: ".hero__swiper__btn-prev",
    },
  });
}

/**
 * Events swiper
 */
if (document.querySelector(".swiper.events__swiper")) {
  new Swiper(".swiper.events__swiper", {
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 2,
      },
      1250: {
        slidesPerView: 3,
      },
    },
    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 1,
    pagination: {
      el: ".events__swiper-pagination",
    },
    navigation: {
      nextEl: ".events__swiper__btn-next",
      prevEl: ".events__swiper__btn-prev",
    },
  });
}

/**
 * Partners swiper
 */
if (document.querySelector(".swiper.partners__swiper")) {
  new Swiper(".swiper.partners__swiper", {
    slidesPerView: 1.45,
    spaceBetween: 30,
    slidesPerGroup: 1,
    pagination: {
      el: ".partners__swiper-pagination",
    },
    navigation: {
      nextEl: ".partners__swiper__btn-next",
      prevEl: ".partners__swiper__btn-prev",
    },
  });
}

/**
 * Mobile menu
 */
if (document.querySelector("button.hamburger")) {
  document
    .querySelector("button.hamburger")
    .addEventListener("click", (evt) => {
      let buttonClasslist = evt.currentTarget.classList;

      buttonClasslist.toggle("active");
      if (buttonClasslist.contains("active")) {
        document.querySelector(".header__mobile").classList.add("active");
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector(".header__mobile").classList.remove("active");
        document.querySelector("body").style = "";
      }
    });
}
if (document.querySelector(".menu-item")) {
  document.querySelectorAll(".menu-item").forEach((i) => {
    i.addEventListener("click", () => {
      document.querySelector("button.hamburger").classList.remove("active");
      document.querySelector(".header__mobile").classList.remove("active");
      document.querySelector("body").style = "";
    });
  });
}

/**
 * Smooth scroll
 */
const anchors = document.querySelectorAll('a[href*="#"]');

if (window.matchMedia("(min-width: 1250px)").matches) {
} else {
  for (let anchor of anchors) {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const blockID = anchor.getAttribute("href").substr(1);

      setTimeout(function () {
        console.log("timeout");
        document.getElementById(blockID).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 500);
    });
  }
}